/*Footer*/
/*Back to top button*/
$("#backtotop").on('click', function() {
    $('html, body').animate({
      scrollTop: $('header').offset().top
    }, 1000);
});


/*Homepage */
/*Text Animation*/
if($('body').hasClass('home')){
  marqueeInit({
        uniqueid: 'mycrawler',
        style: {
            'padding': '5px',
            'width': '100%'
        },
        inc: 5, //speed - pixel increment for each iteration of this marquee's movement
        mouse: false
    });


    /*Change Color Body*/
var target_categories = $("#categories").offset().top - 400;
var target_services = $("#services").offset().top - 400;
// var target_offers = $("#offers").offset().top - 400;
$(window).bind('mousewheel', function(event) {
  if (event.originalEvent.wheelDelta >= 0) { //scroll up
        // $('#about').removeClass('bg_white');
        // $('#textloop').removeClass('bg_gray');
        // $('#lab').removeClass('bg_blue');
  }
  else { //scroll down
    // var w = $(window).scroll(function(){
    //
    //     if ( w.scrollTop() > target_categories ) {
    //         $('#about').addClass('bg_white');
    //     }
    //     if ( w.scrollTop() > target_services ) {
    //         $('#textloop').addClass('bg_gray');
    //     }
    //     if ( w.scrollTop() > target_offers ) {
    //         $('#lab').addClass('bg_blue');
    //     }
    // });
  }
});
}

    /*Home Swipe Js*/
import Swiper, { Navigation } from 'swiper';

      const swiper_gal = new Swiper(".home_swipe", {
            // slidesPerView: 3,
            centeredSlides: true,
            loop: true,
            spaceBetween: 20,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            breakpoints: {
             1280: {
                slidesPerView: 3,
                spaceBetween: 180,
              }
            }
      });

      const swiper_lab = new Swiper(".lab_swipe", {
            // slidesPerView: 3,
            centeredSlides: true,
            loop: true,
            spaceBetween: 75,
            autoplay: {
              delay: 3000,
              disableOnInteraction: true,
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            breakpoints: {
              1280: {
                 slidesPerView: 3,
                 spaceBetween: 75,
               }
             }
      });

      const swiper_prod = new Swiper(".prod_swipe", {
            slidesPerView: 1,
            centeredSlides: false,
            loop: true,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            breakpoints: {
              991: {
                slidesPerView: "1.5",
                spaceBetween: 50,
               }
             }
      });

// About us basic points numbers
if($("#basicpoints").length){
  $('#basicpoints .wrapper_row .row').each(function(i) {
    var number = '0'+ ++i;
    $(this).find('p').append('<span class="number">'+number+'</span>');
  });
}

/*Header */

  /*scroll header*/
$(window).scroll(function() {
  if ($(document).scrollTop() > 80) {
      $('.header_wrapper').addClass('scrolled');
  }
  else{
    $('.header_wrapper').removeClass('scrolled');
  }
});

$(".menu_open button").on('click', function() {
});

  /*hamburger*/
$(".menu_open button").on('click', function() {
      if($(this).hasClass('is-active')){
          $("body").css('overflow-y','scroll');
          $(this).removeClass('is-active');
          $('.headermenu').removeClass('is-active');
          $('.header_wrapper').removeClass('openmenu');
        }
      else{
          $(this).addClass('is-active');
          jQuery("body").css('overflow-y','hidden');
          $('.headermenu').addClass('is-active');
          $('.header_wrapper').addClass('openmenu');
      }
});


/*Sub Menu*/

$(".menu li a").on('click', function() {

  //check if menu item is 3rd level
  if($(this).parents('ul.sub-menu').length) {
    if($(this).parent().find('>.sub-menu').hasClass('active')){
      $(this).parent().find('>.sub-menu').removeClass('active');
      $(this).parent('.menu-item-has-children').removeClass('has-active');
      $(this).removeClass('clicked');
    }else{
      $(this).parent().find('>.sub-menu').addClass('active');
      $(this).parent('.menu-item-has-children').addClass('has-active');

      if($(this).parent('li').hasClass('menu-item-has-children')){
        $(this).addClass('clicked');
      }
    }
  }
  else{
    //clear classes
    $('.menu-item-has-children .sub-menu').removeClass('active');
    $('.menu-item-has-children').removeClass('has-active');
    $('.menu-item-has-children a.clicked').removeClass('clicked');

    if($(this).parent('li').hasClass('menu-item-has-children')){
      if($(this).parent().find('>.sub-menu').hasClass('active')){
          $(this).parent('.menu-item-has-children').removeClass('has-active');
          $(this).removeClass('clicked');
          $(this).parent().find('>.sub-menu').removeClass('active');
      }else{
          $(this).parent('.menu-item-has-children').addClass('has-active');
          $(this).addClass('clicked');
          $(this).parent().find('>.sub-menu').addClass('active');
          // return false;
      }
    }
  }

});

/*Language Switcher*/
// $(".lang_open>a").on('click', function() {
//   if($('.lang_hidden').hasClass('openlang')){
//       $('.lang_hidden').removeClass('openlang');
//       $('.lang_hidden').css('display','none');
//   }else{
//     $('.lang_hidden').addClass('openlang');
//     $('.lang_hidden').css('display','block');
//   }
//   return false;
// });


/*Service*/
/*Custom Accordion*/
if($('body').hasClass('page-template-tmpl_hearingaids')){

  $(".custom_accordion_question .title").on('click', function() {
        if($(this).hasClass('active')){
            $(this).removeClass('active');
            var get_text= $(this).find('p').attr('data-text');
            console.log(get_text);
            $(this).find('p').html(get_text);
        }else{
            $(this).addClass('active');
            var get_text= $(this).find('p').attr('data-text');
            var get_resttext= $(this).find('p').attr('data-rest');
            console.log(get_text);
            console.log(get_resttext);
            $(this).find('p').html('<strong>'+get_text+'</strong>'+get_resttext);
        }
  });

}else{

  $(".custom_accordion_question .title").on('click', function() {
        if($(this).hasClass('active')){
            $(this).removeClass('active');
            //$('.custom_accordion_answer .text').slideUp('fast');
            $('.custom_accordion_answer .text').removeClass('active');
        }else{
            $(".custom_accordion_question .title").removeClass('active');
            //$(".custom_accordion_answer .text").slideUp('fast');
            $('.custom_accordion_answer .text').removeClass('active');
            $(this).addClass('active');
            var get_title=$(this).attr('data-title');
            $( ".custom_accordion_answer .text").each(function() {
              var get_text=$(this).attr('data-text');
              if(get_text==get_title){
              //  $(this).slideDown('slow');
                $(this).addClass('active');
              }
            });
          //   $('html, body').animate({
          //       scrollTop: $("#details").offset().top -50
          // }, 2000);
        }
  });

}

/*Proucts*/
/*Custom Accordion*/
$(".products-row .col .spec").on('click', function() {
      if($(this).parent().hasClass('active')){
          $(this).parent().removeClass('active');
          $( ".wrapper .thespecs").removeClass('active');
          $('.backdrop').removeClass('active');
      }else{
          $(".products-row .col").removeClass('active');
          $( ".wrapper .thespecs").removeClass('active');
          $(this).parent().addClass('active');
          var get_title_product=$(this).parent().attr('data-title');
          $( ".wrapper .thespecs").each(function() {
            var get_text_product=$(this).attr('data-spec');
            if(get_text_product==get_title_product){
              $(this).addClass('active');
              var get_image_src=$(this).find('.modal_info').find('img').data('src');
              console.log(get_image_src);
              $(this).find('.modal_info').find('img').attr('src',get_image_src);
              $('.backdrop').addClass('active');
            }
          });
          //   $('html, body').animate({
          //       scrollTop: $(".thespecs").offset().top -50
          // }, 2000);
      }
});


$(".button_close").on('click', function() {
  $( ".wrapper .thespecs").removeClass('active');
  $('.backdrop').removeClass('active');
  $(".products-row .col").removeClass('active');
  $('#bookform').removeClass('active');
});


$(".book a").on('click', function() {
    if($('#bookform').hasClass('active')){

    }else{
        $('#bookform').addClass('active');
        $('.backdrop').addClass('active');
    }
    return false;
});


/*Text Animation*/
if($('body').hasClass('page-template-tmpl_hearingproducts')){
  marqueeInit({
        uniqueid: 'mycrawler',
        style: {
            'padding': '5px',
            'width': '100%'
        },
        inc: 5, //speed - pixel increment for each iteration of this marquee's movement
        mouse: false
    });
}

jQuery(document).ready(
    function($)
    {
        jQuery("h1, h2, h3, h4, h5, h6, p, span, a, li").each(
            function()
            {
                if($(this).css('text-transform') == 'uppercase' || $(this).css('font-family') == 'Flounder Pro')
                {
                    jQuery(this).html(jQuery(this).html().replace(/[ά]/g,"α"));
                    jQuery(this).html(jQuery(this).html().replace(/[έ]/g,"ε"));
                    jQuery(this).html(jQuery(this).html().replace(/[ή]/g,"η"));
                    jQuery(this).html(jQuery(this).html().replace(/[ύ]/g,"υ"));
                    jQuery(this).html(jQuery(this).html().replace(/[ώ]/g,"ω"));
                    jQuery(this).html(jQuery(this).html().replace(/[ί]/g,"ι"));
                    jQuery(this).html(jQuery(this).html().replace(/[ό]/g,"ο"));

            }
			});
    }
);
