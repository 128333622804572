var mapStyle =[
    // {
    //     "featureType": "landscape",
    //     "elementType": "all",
    //     "stylers": [
    //         {
    //             "color": "#ffffff"
    //         }
    //     ]
    // },
    // {
    //     "featureType": "water",
    //     "elementType": "all",
    //     "stylers": [
    //         {
    //             "visibility": "on"
    //         },
    //         {
    //             "color": "#e7e7e1"
    //         }
    //     ]
    // }
];

/*id of map here*/
if ($("#mapalex").length > 0) {
  google.maps.event.addDomListener(window, 'load', initialize_map);

}

function initialize_map() {
  var controlPosition = google.maps.ControlPosition.RIGHT_CENTER;
  var coords_dynamic = jQuery("#mapalex").attr('data-coords');
  var splitedcoords = coords_dynamic.split(',');

  var myLatlng = new google.maps.LatLng(splitedcoords[0], splitedcoords[1]);

  var isDraggable = true;
  var mapOptions = {
    zoom: 13,
    gestureHandling: 'cooperative',
    center: myLatlng,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
     styles:mapStyle,
    scrollwheel: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles:mapStyle
  };


  var map = new google.maps.Map(document.getElementById('mapalex'),
    mapOptions);
  var iconsource = 'https://www.earplus.gr/wp-content/themes/earplus/assets/images/marker.png';/*map pointer here*/
  var icons = {
    markericon: {
      icon: iconsource
    }
  };

  var marker = new google.maps.Marker({
    position: myLatlng,
    icon: {
url: "https://www.earplus.gr/wp-content/themes/earplus/assets/images/marker.png"
},
    map: map
  });

  marker.setAnimation(google.maps.Animation.BOUNCE);
  setTimeout(function(){ marker.setAnimation(null); }, 1000);

  //Map style is not mandatory
  var styledMap = new google.maps.StyledMapType(mapStyle, {
    name: "Styled Map"
  });
  map.mapTypes.set('mapStyle', styledMap);
  map.setMapTypeId('mapStyle')

}


/*Map Stores*/
var fselect_value;
var data_area;
//var select_value_filter;
if ($("#map").length > 0) {

  google.maps.event.addDomListener(window, 'load', initialize_map_stores('default','default',1)); //default initialize
  $('.select_stores .select_store_select').change(function() {
    fselect_value=$(this).find(':selected').attr('data-store');
    if(data_area){
      google.maps.event.addDomListener(window, 'load', initialize_map_stores(fselect_value,data_area,3)); //type and area
    }else{
      google.maps.event.addDomListener(window, 'load', initialize_map_stores(fselect_value,'default',2)); //only type

    }

    $('.select_store_city option').each(function() {
         var cselect_value=$(this).attr('data-area');
         var flag=0;
         if(fselect_value=='default'){
            flag=1;
         }else{
           if(cselect_value=='default'){
              flag=1;
           }else{
             for (i = 0; i < json.length; i++) {
               if(json[i]['Area']==cselect_value && json[i]['Type']==fselect_value){
                    flag=1;
               }
             }
           }
         }

         if(flag==0){
              $(this).attr('disabled','disabled');
         }else{
             $(this).removeAttr('disabled');
         }

     });
  });



  $('.select_stores .select_store_city').change(function() {
      data_area = $(this).find(":selected").attr('data-area');
      if(fselect_value){
        google.maps.event.addDomListener(window, 'load', initialize_map_stores(fselect_value,data_area,3));//type and area
      } else{
        google.maps.event.addDomListener(window, 'load', initialize_map_stores("default",data_area,3));//only area
      }
  });

}


function initialize_map_stores(type,area,condition) {


  var controlPosition = google.maps.ControlPosition.RIGHT_CENTER;
  var coords_dynamic = jQuery("#map").attr('data-coords');
  var splitedcoords = coords_dynamic.split(',');

  var myLatlng = new google.maps.LatLng(splitedcoords[0], splitedcoords[1]);

  var isDraggable = true;
  var mapOptions = {
    zoom: 13,
    gestureHandling: 'cooperative',
    center: myLatlng,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
     styles:mapStyle,
    scrollwheel: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles:mapStyle
  };


  var map = new google.maps.Map(document.getElementById('map'),
    mapOptions);
  var iconsource = 'https://www.earplus.gr/wp-content/themes/earplus/assets/images/marker.png';/*map pointer here*/
  var icons = {
    markericon: {
      icon: iconsource
    }
  };

  var marker = new google.maps.Marker({
    position: myLatlng,
    icon: {
      url: "https://www.earplus.gr/wp-content/themes/earplus/assets/images/marker.png"
    },
    map: map
  });

  var infowindow = new google.maps.InfoWindow();
  var marker, i;
  var bounds = new google.maps.LatLngBounds();

    /*New*/
    if(condition==1){
        //default
        for (i = 0; i < json.length; i++) {

            var coords_dynamic_stores=json[i]['Coords'];
            var splitedcoords_stores = coords_dynamic_stores.split(',');
            marker = new google.maps.Marker({
              position: new google.maps.LatLng(splitedcoords_stores[0], splitedcoords_stores[1]),
              map: map,
              icon: {
                url: "https://www.earplus.gr/wp-content/themes/earplus/assets/images/marker_old.png"
            },
            });
            bounds.extend(marker.position);
            google.maps.event.addListener(marker, 'click', (function(marker, i) {
              return function() {
                infowindow.setContent('<p>'+json[i]['City']+'</p>'+'<p>'+json[i]['Address']+'</p>'+'<p>'+json[i]['Phone']+'</p>'+'<p>'+json[i]['Email']+'</p>');
                infowindow.open(map, marker);
              }
            })(marker, i));

        }

      }else if(condition==2){
        //only type
        for (i = 0; i < json.length; i++) {
            if(json[i]['Type']==type){
              var coords_dynamic_stores=json[i]['Coords'];
              var splitedcoords_stores = coords_dynamic_stores.split(',');
              marker = new google.maps.Marker({
                position: new google.maps.LatLng(splitedcoords_stores[0], splitedcoords_stores[1]),
                map: map,
                icon: {
                  url: "https://www.earplus.gr/wp-content/themes/earplus/assets/images/marker.png"
              },
              });
              bounds.extend(marker.position);
              google.maps.event.addListener(marker, 'click', (function(marker, i) {
                return function() {
                  infowindow.setContent('<p>'+json[i]['City']+'</p>'+'<p>'+json[i]['Address']+'</p>'+'<p>'+json[i]['Phone']+'</p>'+'<p>'+json[i]['Email']+'</p>');
                  infowindow.open(map, marker);
                }
              })(marker, i));
            }
        }


      }else if(condition==3){
          //city and/or type

          var flag=1;
          for (i = 0; i < json.length; i++) {
              if(json[i]['Type']==type && json[i]['Area']==area){
                flag=0;
                var coords_dynamic_stores=json[i]['Coords'];
                var splitedcoords_stores = coords_dynamic_stores.split(',');
                marker = new google.maps.Marker({
                  position: new google.maps.LatLng(splitedcoords_stores[0], splitedcoords_stores[1]),
                  map: map,
                  icon: {
                    url: "https://www.earplus.gr/wp-content/themes/earplus/assets/images/marker.png"
                },
                });
                bounds.extend(marker.position);
                google.maps.event.addListener(marker, 'click', (function(marker, i) {
                  return function() {
                    infowindow.setContent('<p>'+json[i]['City']+'</p>'+'<p>'+json[i]['Address']+'</p>'+'<p>'+json[i]['Phone']+'</p>'+'<p>'+json[i]['Email']+'</p>');
                    infowindow.open(map, marker);
                  }
                })(marker, i));
              }
          }

          if(type=='default'){
            for (i = 0; i < json.length; i++) {
                flag=0;
                if(json[i]['Area']==area){
                  var coords_dynamic_stores=json[i]['Coords'];
                  var splitedcoords_stores = coords_dynamic_stores.split(',');
                  marker = new google.maps.Marker({
                    position: new google.maps.LatLng(splitedcoords_stores[0], splitedcoords_stores[1]),
                    map: map,
                    icon: {
                      url: "https://www.earplus.gr/wp-content/themes/earplus/assets/images/marker.png"
                  },
                  });
                  bounds.extend(marker.position);
                  google.maps.event.addListener(marker, 'click', (function(marker, i) {
                    return function() {
                      infowindow.setContent('<p>'+json[i]['City']+'</p>'+'<p>'+json[i]['Address']+'</p>'+'<p>'+json[i]['Phone']+'</p>'+'<p>'+json[i]['Email']+'</p>');
                      infowindow.open(map, marker);
                    }
                  })(marker, i));
                }
            }
          }

          if(flag==1){
            alert('Δεν υπάρχει τύπος '+type+' στη περιοχή '+area+" ! Επιλέξτε κάτι διαφορετικό");
            for (i = 0; i < json.length; i++) {

                var coords_dynamic_stores=json[i]['Coords'];
                var splitedcoords_stores = coords_dynamic_stores.split(',');
                marker = new google.maps.Marker({
                  position: new google.maps.LatLng(splitedcoords_stores[0], splitedcoords_stores[1]),
                  map: map,
                  icon: {
                    url: "https://www.earplus.gr/wp-content/themes/earplus/assets/images/marker_old.png"
                },
                });
                bounds.extend(marker.position);
                google.maps.event.addListener(marker, 'click', (function(marker, i) {
                  return function() {
                    infowindow.setContent('<p>'+json[i]['City']+'</p>'+'<p>'+json[i]['Address']+'</p>'+'<p>'+json[i]['Phone']+'</p>'+'<p>'+json[i]['Email']+'</p>');
                    infowindow.open(map, marker);
                  }
                })(marker, i));

            }
          }

          if(type=='default' && area=='default'){
            for (i = 0; i < json.length; i++) {

                var coords_dynamic_stores=json[i]['Coords'];
                var splitedcoords_stores = coords_dynamic_stores.split(',');
                marker = new google.maps.Marker({
                  position: new google.maps.LatLng(splitedcoords_stores[0], splitedcoords_stores[1]),
                  map: map,
                  icon: {
                    url: "https://www.earplus.gr/wp-content/themes/earplus/assets/images/marker_old.png"
                },
                });
                bounds.extend(marker.position);
                google.maps.event.addListener(marker, 'click', (function(marker, i) {
                  return function() {
                    infowindow.setContent('<p>'+json[i]['City']+'</p>'+'<p>'+json[i]['Address']+'</p>'+'<p>'+json[i]['Phone']+'</p>'+'<p>'+json[i]['Email']+'</p>');
                    infowindow.open(map, marker);
                  }
                })(marker, i));

            }
          }

      }else{
        //none

    }

        map.fitBounds(bounds);


  //Map style is not mandatory
  var styledMap = new google.maps.StyledMapType(mapStyle, {
    name: "Styled Map"
  });
  map.mapTypes.set('mapStyle', styledMap);
  map.setMapTypeId('mapStyle')

}
